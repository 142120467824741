<template>
  <van-popup v-model="showFilter" position="right">
    <div class="filter-list">
      <div class="content">
        <van-cell-group :title="title">
          <!-- <van-cell> -->
          <van-search
            v-model="value"
            clearable
            placeholder="请输入搜索关键词"
            @search="onSearch"
          />
          <!-- </van-cell> -->
          <van-cell
            v-for="(item, index) in eachList"
            :key="index"
            @click="selcetData(item)"
          >
            <template #right-icon>
              <div class="cust-icon">
                <van-icon
                  v-show="selectList.includes(item[valueKey])"
                  name="success"
                />
              </div>
            </template>
            <template #title>
              <span
                :class="
                  selectList.includes(item[valueKey]) ? 'custom-title' : ''
                "
              >
                {{ item[showName] }}
              </span>
            </template>
          </van-cell>
        </van-cell-group>
        <van-field name="switch" label-width="60" label="处理状态">
          <template #input>
            <van-radio-group v-model="switchChecked" direction="horizontal">
              <div class="radio-group">
                <van-radio name="0">全部</van-radio>
                <van-radio name="1">未处理</van-radio>
                <van-radio name="2">已处理</van-radio>
              </div>
            </van-radio-group>
          </template>
        </van-field>
      </div>
      <div class="footer">
        <van-button type="default" @click="resetFilter">取 消</van-button>
        <van-button type="info" @click="confirmFilter">确 定</van-button>
      </div>
    </div>
  </van-popup>
</template>
<script>
export default {
  props: {
    // 列表展示的key
    showName: {
      type: String,
      default: "name",
    },
    // 单项唯一标识
    valueKey: {
      type: String,
      default: "id",
    },
    // 列表
    list: {
      type: Array,
      default: () => [],
    },
    // 默认选中
    defaultList: {
      type: Array,
      default: () => [],
    },
    // 标题
    title: {
      type: String,
      default: "请选择",
    },
    // 打开筛选，需要.sync修饰符
    show: {
      type: Boolean,
      default: false,
    },
    // 是否支持多选
    multiple: {
      type: Boolean,
      default: true,
    },
    // 是否前端检索
    webFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectList: [],
      value: "",
      switchChecked: "0",
    };
  },
  watch: {
    show(val) {
      if (val) {
        if (!this.selectList.length) {
          this.selectList = this.defaultList.map((v) => v[this.valueKey]);
        }
      }
    },
  },
  computed: {
    showFilter: {
      set(val) {
        this.$emit("update:show", val);
      },
      get() {
        return this.show;
      },
    },
    eachList() {
      if (!this.webFilter || !this.value) {
        return this.list;
      }
      return this.list.filter((v) => JSON.stringify(v).includes(this.value));
    },
  },
  methods: {
    selcetData(item) {
      const index = this.selectList.indexOf(item[this.valueKey]);
      if (this.multiple) {
        if (index < 0) {
          this.selectList.push(item[this.valueKey]);
        } else {
          this.selectList.splice(index, 1);
        }
      } else {
        if (index < 0) {
          this.selectList = [item[this.valueKey]];
        } else {
          this.selectList = [];
        }
      }
    },
    confirmFilter() {
      // const list = this.list.filter((v) =>
      //   this.selectList.includes(v[this.valueKey])
      // );
      // if (this.multiple) {
      //   this.$emit("confirm", list);
      // } else {
      //   this.$emit("confirm", list[0]);
      // }
      this.showFilter = false;
      this.$emit("confirm", {
        keyword: this.value,
        switchChecked: this.switchChecked,
      });
    },
    resetFilter() {
      this.selectList = [];
      this.showFilter = false;
    },
    onSearch() {
      this.$emit("search", this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
$selected: #1989fa;
.van-popup--right {
  height: 100%;
}
.switch-label {
  color: #888;
  padding: 0 10px;
  &.active {
    color: #333;
  }
}
.filter-list {
  width: 75vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: auto;
    .cust-icon {
      width: 18px;
      display: flex;
      align-items: center;
      color: $selected;
    }
    .custom-title {
      color: $selected;
    }
  }
  .radio-group {
    line-height: 1.5;
    .van-radio {
      margin-bottom: 6px;
    }
  }
  .footer {
    height: 44px;
    border-top: 1px solid #eee;
    display: flex;
    .van-button {
      flex: 1;
    }
  }
}
</style>