import { render, staticRenderFns } from "./closeAds.vue?vue&type=template&id=72763f48"
import script from "./closeAds.vue?vue&type=script&lang=js"
export * from "./closeAds.vue?vue&type=script&lang=js"
import style0 from "./closeAds.vue?vue&type=style&index=0&id=72763f48&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports