<template>
  <div class="change-budget-popup">
    <h2>{{ title }}</h2>

    <div class="content">
      <div class="report-data">
        <van-cell-group>
          <van-cell title="系统建议">
            <template>
              <div :class="suggestObj.type">{{ suggestObj.text }}</div>
            </template>
          </van-cell>
          <van-cell>
            <template #title>
              <div class="flex">
                <span class="van-field__label">{{ suggestType }}</span>
                <span>{{ suggestText }} </span>
              </div>
            </template>
            <template #label>
              <van-field class="change-cell" label="调整金额">
                <template #input>
                  <el-input-number
                    v-model="subBudget"
                    size="small"
                    :disabled="cannotSubmit"
                  ></el-input-number>
                </template>
              </van-field>
            </template>
          </van-cell>
        </van-cell-group>
        <div class="report-result">
          <title-top :info="info"></title-top>
          <van-cell title="结果说明">
            <template #label>
              <div v-html="executionDesc"></div>
            </template>
          </van-cell>
          <report-data
            :show-date="showDate"
            :detail="detail || {}"
            :adset.sync="adsets"
            @change-date="changeDate"
            @click-adset="pausedAdset"
          ></report-data>
        </div>
      </div>
      <div class="button-groups">
        <van-button type="info" block plain id="closeAdsConfirm" @click="close">
          关闭{{ title.substr(0, title.length - 2) }}
        </van-button>
        <van-button
          type="info"
          block
          id="changeBudgetSubmit"
          @click="submit"
          :disabled="cannotSubmit"
        >
          修改预算
        </van-button>
      </div>
    </div>
  </div>
</template>
<script>
import buggetMixins from "./buggetMixins";
import titleTop from "./title";
import { mutatePricing, pausedAd, activeAd } from "@/api/autoRulesResult";
import { Dialog } from "vant";
export default {
  mixins: [buggetMixins],
  components: {
    titleTop,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      subBudget: 0,
      flag: false,
      suggestType: "当前预算",
      cannotSubmit: false,
      min: 0,
      max: Infinity,
      status: true,
    };
  },
  watch: {
    info(val) {
      if (val) {
        if (this.info) {
          if (this.info.objType == "campaign") {
            this.title = "广告系列数据";
          } else {
            this.title = "广告组数据";
          }
          this.getSuggest(this.info);
        }
        this.getDetail(this.info);
      }
    },
    form(val) {
      if (this.form) {
        if (this.form.amountType == "daily_budget") {
          this.suggestType = "当前日预算";
        } else {
          this.suggestType = "当前总预算";
        }
        if (this.form?.execution?.limit) {
          this.min = parseFloat(this.form.execution.limit[0]) || 0;
          this.max = parseFloat(this.form.execution.limit[1]) || Infinity;
        } else {
          this.min = 0;
          this.max = Infinity;
        }
        this.subBudget = this.form.subAmount;
        this.cannotSubmit = this.form.cannotchange;
      }
    },
  },
  computed: {
    suggestText() {
      if (this.form) {
        if (!this.form.cannotchange)
          return this.form.amount + "(当前广告账户币种)";
        return this.title.includes("系列")
          ? "当前使用的是广告组预算，无法修改"
          : "当前使用的是广告系列预算，无法修改";
      }
      return 0;
    },
    suggestObj() {
      let detail = this.info;
      if (!detail) return {};
      let obj = {};
      let value = {};
      if (detail.executionType == "PAUSE") {
        obj.type = "danger";
        obj.text = "关闭" + this.title.substr(0, this.title.length - 2);
      } else if (detail.executionType == "NOTIFICATION") {
        obj.type = "";
        // obj.text = detail
      } else {
        try {
          value = detail.executionSpec.executionOptions[0]["value"];
          value = JSON.parse(value);
        } catch (error) {}
        if (value.amount > 0) {
          obj.type = "success";
          obj.text = `建议：预算增加${value.amount}${
            value.unit == "PERCENTAGE" ? "%" : "元"
          }`;
        } else if (value.amount < 0) {
          obj.type = "danger";
          obj.text = `建议：预算减少${value.amount}${
            value.unit == "PERCENTAGE" ? "%" : "元"
          }`;
        } else {
          obj.type = "success";
          obj.text = `建议：变更预算，最少${this.min}，最多${this.max}`;
        }
      }
      return obj;
    },
    executionDesc() {
      console.log(this.info,this.form);
      if (!this.info || !this.info.executionDesc) {
        return "";
      }
      if (!this.info.executionDesc.includes("执行明细")) {
        return this.info.executionDesc;
      }
      let text = this.info.executionDesc.substr(
        0,
        this.info.executionDesc.length - 1
      );
      return text
        .replace("(执行明细", "<br/>执行明细")
        .replace(/\-\>/g, "<br/>");
    },
  },
  methods: {
    getSuggest(detail) {
      let obj = {};
      let value = {};
      if (detail.executionType == "PAUSE") {
        obj.type = "danger";
        obj.text = "关闭" + this.title.substr(0, this.title.length - 2);
      } else if (detail.executionType == "NOTIFICATION") {
        obj.type = "";
        // obj.text = detail
      } else {
        try {
          value = detail.executionSpec.executionOptions[0]["value"];
          value = JSON.parse(value);
        } catch (error) {}
        if (value.amount > 0) {
          obj.type = "success";
          obj.text = `建议：预算增加${value.amount}${
            value.unit == "PERCENTAGE" ? "%" : "元"
          }`;
        } else if (value.amount < 0) {
          obj.type = "danger";
          obj.text = `建议：预算减少${value.amount}${
            value.unit == "PERCENTAGE" ? "%" : "元"
          }`;
        } else {
          obj.type = "success";
          obj.text = `建议：变更预算，最少${this.min}，最多${this.max}`;
        }
      }
      this.suggest = obj;
    },
    submit() {
      if (this.flag) return;
      if (this.cannotSubmit) {
        const message = this.title.includes("系列")
          ? "当前使用的是广告组预算，无法修改"
          : "当前使用的是广告系列预算，无法修改";
        this.$message.error(message);
        return;
      }
      const alertMessage = this.title.includes("系列")
        ? "确定要保存对广告系列的修改吗？"
        : "确定要保存对广告组的修改吗？";
      Dialog.confirm({
        title: "确认修改",
        message: alertMessage,
      })
        .then(() => {
          this.flag = true;
          const obj = {
            id: this.form.id,
            name: this.form.name,
            daily_budget: null,
            lifetime_budget: null,
            bid_amount: null,
            before_amount: this.form.bid_amount,
            before_daily_budget: this.form.before_daily_budget,
            before_lifetime_budget: this.form.before_lifetime_budget
          };
          obj[this.form.amountType] = this.subBudget * 100;
          const data = {
            level: this.form.level,
            objects: [obj],
          };
          this.$showLoading();
          mutatePricing(data, this.info.accountId)
            .then((res) => {
              this.flag = false;
              this.$hideLoading();
              if (res.code == 0) {
                this.$message.success("修改成功");
                const form = JSON.parse(JSON.stringify(this.form));
                form.amount = this.subBudget;
                this.$emit("update:form", form);
                this.$emit("close");
              }
            })
            .catch((err) => {
              this.flag = false;
              console.log(err);
              this.$hideLoading();
            });
        })
        .catch(() => {});
    },
    close() {
      if (this.flag) return;
      const alertMessage = this.title.includes("系列")
        ? "确定要关闭该广告系列吗？"
        : "确定要关闭该广告组吗？";
      Dialog.confirm({
        title: "确认修改",
        message: alertMessage,
      })
        .then(() => {
          this.flag = true;
          this.$message.info("正在关闭...");
          const data = {
            id: [this.info.objId],
            level: this.info.objType,
          };
          pausedAd(data, this.info.accountId)
            .then((res) => {
              this.flag = false;
              // console.log(res);
              if (res.code == 0) {
                this.$emit("close");
                this.$message.success(
                  "已关闭该" +
                    (data.level == "campaign" ? "广告系列" : "广告组")
                );
              }
            })
            .catch((err) => {
              this.flag = false;
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    pausedAdset(e) {
      // console.log(e);
      if (this.flag) return;
      const index = this.adsets.findIndex(v => v.id == e.id)
      const item = this.adsets[index]
      if (e.active) {
        Dialog.confirm({
          title: "确认修改",
          message: "确定要关闭该广告组吗?",
        })
          .then(() => {
            this.flag = true;
            this.$message.info("正在关闭...");
            const data = {
              id: [item.id],
              level: item.level,
            };
            pausedAd(data, this.info.accountId)
              .then((res) => {
                this.flag = false;
                // console.log(res);
                if (res.code == 0) {
                  item.active = false
                  item.status = "PAUSED"
                  this.$set(this.adsets, index, item)
                  this.$emit("close-adset");
                  this.$message.success(
                    "已关闭该广告组"
                  );
                }
              })
              .catch((err) => {
                this.flag = false;
                console.log(err);
              });
          })
          .catch((err) => {});
      } else {
        // activeAd
        Dialog.confirm({
          title: "确认修改",
          message: "确定要开启该广告组吗?",
        })
          .then(() => {
            this.flag = true;
            this.$message.info("正在关闭...");
            const data = {
              id: [item.id],
              level: item.level,
            };
            activeAd(data, this.info.accountId)
              .then((res) => {
                this.flag = false;
                // console.log(res);
                if (res.code == 0) {
                  item.active = true
                  item.status = "ACTIVE"
                  this.$set(this.adsets, index, item)
                  this.$emit("close-adset");
                  this.$message.success(
                    "已开启该广告组"
                  );
                }
              })
              .catch((err) => {
                this.flag = false;
                console.log(err);
              });
          })
          .catch((err) => {});
      }
    },
  },
  mounted() {
    if (this.info) {
      if (this.info.objType == "campaign") {
        this.title = "广告系列数据";
      } else {
        this.title = "广告组数据";
      }
      this.getSuggest(this.info);
    }
    this.getDetail(this.info);
    if (this.form) {
      if (this.form.amountType == "daily_budget") {
        this.suggestType = "当前日预算";
      } else {
        this.suggestType = "当前总预算";
      }
      if (this.form?.execution?.limit) {
        this.min = parseFloat(this.form.execution.limit[0]) || 0;
        this.max = parseFloat(this.form.execution.limit[1]) || Infinity;
      } else {
        this.min = 0;
        this.max = Infinity;
      }
      this.subBudget = this.form.subAmount;
      this.cannotSubmit = this.form.cannotchange;
    }
  },
};
</script>
<style lang="scss">
$danger: #ae0000;
$success: #4cad54;
$m-theme: #1b82d2;
.flex {
  display: flex;
}
.change-budget-popup {
  h2 {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }
  .change-cell {
    margin-bottom: 8px;
    &::after {
      display: none;
    }
  }
  .content {
    height: 90vh;
    display: flex;
    flex-direction: column;
    .report-data {
      flex: 1;
      flex-direction: column;
      display: flex;
      .report-result {
        flex: 1;
        overflow-y: auto;
        position: relative;
      }
      .van-field {
        padding: 0;
      }
      .danger {
        color: $danger;
      }
      .success {
        color: $success;
      }
    }
  }
  .button-groups {
    display: flex;
    #closeAdsConfirm,
    #changeBudgetSubmit {
      flex: 1;
    }
  }
}
</style>