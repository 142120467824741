<template>
  <div
    class="much-more"
    :style="{ top: positionX + 'px', bottom: positionB }"
    :class="showWidth ? 'show' : ''"
    v-drag
  >
    <button
      class="view-more"
      @blur="showMore = false"
      @click="showMore = !showMore"
    >
      更多功能<van-icon
        :class="showMore ? 'show' : ''"
        name="arrow-left"
        size="14"
      />
    </button>
    <div class="tools" :class="showMore ? 'show' : ''">
      <div class="tool" @click="$emit('click-replay')">
        <i class="el-icon-refresh"></i>
        <p>重采</p>
      </div>
      <div class="tool" @click="$emit('click-filters')">
        <van-icon name="filter-o" size="20" />
        <p>筛选</p>
      </div>
    </div>
  </div>
</template>
<script>
let _this;
export default {
  data() {
    return {
      showMore: false,
      positionX: "",
      positionB: "200px",
      showWidth: false,
    };
  },
  directives: {
    drag: {
      bind: (el) => {
        let maxHeight = window.screen.height - 100;
        let odiv = el;
        odiv.ontouchstart = (e) => {
          let disY = e.touches[0].clientY - odiv.offsetTop;
          document.ontouchmove = (ele) => {
            let top = ele.touches[0].clientY - disY;
            top = top <= 0 ? 0 : top;
            top = top >= maxHeight ? maxHeight : top;
            _this.positionX = top;
            _this.positionB = "auto";
          };
          document.ontouchend = (e) => {
            document.ontouchmove = null;
            document.ontouchend = null;
          };
        };
      },
    },
  },
  watch:{
    showMore (val) {
      if (val) {
        this.showWidth = true;
      } else {
        setTimeout(() => {
          this.showWidth = false
        },300)
      }
    }
  },
  created() {
    _this = this;
  },
};
</script>
<style lang="scss" scoped>
.much-more {
  // transition: all 0.3s ease;
  display: flex;
  color: #fff;
  position: fixed;
  left: auto;
  right: 0;
  width: 25px;
  &.show {
    width: auto;
  }
  .view-more {
    background: rgba($color: #000000, $alpha: 0.6);
    font-size: 14px;
    width: 24px;
    text-align: center;
    line-height: 1;
    border-radius: 4px 0 0 4px;
    padding: 4px 0 0;
    margin-right: 1px;
    border: 0;
    outline: none;
    height: 80px;
    .van-icon {
      transition: all 0.3s ease;
      &.show {
        transform: rotateZ(180deg);
      }
    }
  }
  .tools {
    display: flex;
    align-items: center;
    text-align: center;
    background: rgba($color: #000000, $alpha: 0.6);
    border-radius: 0 4px 4px 0;
    height: 80px;
    width: 0;
    transition: all 0.3s ease;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    &.show {
      width: 110px;
    }
    .tool {
      padding: 10px;
      .el-icon-refresh {
        font-size: 24px;
      }
    }
  }
}
</style>