<template>
  <div class="change-budget-popup">
    <h2>{{ title }}</h2>
    
    <div class="content">
      <title-top :info="info"></title-top>
      <div class="report-data">
        <van-cell-group>
          <van-cell title="结果说明">
            <template #label>
              <div v-html="executionDesc"></div>
            </template>
          </van-cell>
          <report-data
            :show-date="showDate"
            @change-date="changeDate"
            :detail="detail || {}"
            :adset="adsets"
          ></report-data>
        </van-cell-group>
      </div>
    </div>
  </div>
</template>
<script>
import buggetMixins from "./buggetMixins";
import titleTop from "./title";
export default {
  mixins: [buggetMixins],
  computed: {
    executionDesc() {
      if(!this.info || !this.info.executionDesc) {
        return ""
      }
      if(!this.info.executionDesc.includes("执行明细")) {
        return this.info.executionDesc
      }
      let text = this.info.executionDesc.substr(0,this.info.executionDesc.length-1);
      return text.replace('(执行明细','<br/>执行明细').replace(/\-\>/g,'<br/>')
    }
  },
  components:{
    titleTop
  },
  watch: {
    info(val) {
      if (val) {
        if (this.info.objType == "campaign") {
          this.title = "广告系列数据";
        } else {
          this.title = "广告组数据";
        }
        this.getDetail(this.info);
      }
    },
  },
  mounted() {
    if (this.info) {
      if (this.info.objType == "campaign") {
        this.title = "广告系列数据";
      } else {
        this.title = "广告组数据";
      }
    }
    this.getDetail(this.info);
  },
};
</script>
<style lang="scss">
$danger: #ae0000;
$success: #4cad54;
$m-theme: #1b82d2;
.flex {
  display: flex;
}
.change-budget-popup {
  h2 {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }
  .content {
    height: 80vh;
    display: flex;
    flex-direction: column;
    .report-data {
      flex: 1;
      overflow-y: auto;
      position: relative;
      padding-bottom: 15px;
      .van-field {
        padding: 0;
      }
      .danger {
        color: $danger;
      }
      .success {
        color: $success;
      }
    }
  }
}
</style>