<template>
  <div class="change-budget-popup">
    <h2>{{ title }}</h2>
    
    <div class="content">
      <title-top :info="info"></title-top>
      <div class="report-data">
        <van-cell-group>
          <van-cell title="系统建议">
            <template>
              <div class="danger">{{ suggest }}</div>
            </template>
          </van-cell>
        </van-cell-group>
        <div class="report-result">
          <van-cell title="结果说明">
            <template #label>
              <div v-html="executionDesc"></div>
            </template>
          </van-cell>
          <report-data
            :show-date="showDate"
            @change-date="changeDate"
            :detail="detail || {}"
            :adset="adsets"
          ></report-data>
        </div>
      </div>
      <van-button type="danger" id="closeAdsConfirm" @click="submit">确 定 关 闭</van-button>
    </div>
  </div>
</template>
<script>
import buggetMixins from "./buggetMixins";
import titleTop from './title.vue'
import { pausedAd } from "@/api/autoRulesResult";
import { Dialog } from "vant";
export default {
  mixins: [buggetMixins],
  data() {
    return {
      flag: false,
    };
  },
  components:{
    titleTop
  },
  computed: {
    executionDesc() {
      if(!this.info || !this.info.executionDesc) {
        return ""
      }
      if(!this.info.executionDesc.includes("执行明细")) {
        return this.info.executionDesc
      }
      let text = this.info.executionDesc.substr(0,this.info.executionDesc.length-1);
      return text.replace('(执行明细','<br/>执行明细').replace(/\-\>/g,'<br/>')
    }
  },
  watch: {
    info(val) {
      if (val) {
        if (this.info.objType == "campaign") {
          this.title = "广告系列数据";
          this.suggest = "关闭广告系列";
        } else {
          this.title = "广告组数据";
          this.suggest = "关闭广告组";
        }
        this.getDetail(this.info);
      }
    },
  },
  methods: {
    submit() {
      if (this.flag) return;
      const alertMessage = this.title.includes("系列")
        ? "确定要关闭该广告系列吗？"
        : "确定要关闭该广告组吗？";
      Dialog.confirm({
        title: "确认修改",
        message: alertMessage,
      })
        .then(() => {
          this.flag = true;
          this.$message.info("正在关闭...");
          const data = {
            id: [this.info.objId],
            level: this.info.objType,
          };
          pausedAd(data, this.info.accountId)
            .then((res) => {
              this.flag = false;
              // console.log(res);
              if (res.code == 0) {
                this.$emit("close");
                this.$message.success(
                  "已关闭该" +
                    (data.level == "campaign" ? "广告系列" : "广告组")
                );
              }
            })
            .catch((err) => {
              this.flag = false;
              console.log(err);
            });
        })
        .catch(() => {});
    },
  },
  mounted() {
    if (this.info) {
      if (this.info.objType == "campaign") {
        this.title = "广告系列数据";
        this.suggest = "关闭广告系列";
      } else {
        this.title = "广告组数据";
        this.suggest = "关闭广告组";
      }
    }
    this.getDetail(this.info);
  },
};
</script>
<style lang="scss">
$danger: #ae0000;
$success: #4cad54;
$m-theme: #1b82d2;
.flex {
  display: flex;
}
.change-budget-popup {
  h2 {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }
  .content {
    height: 90vh;
    display: flex;
    flex-direction: column;
    .report-data {
      flex: 1;
      display: flex;
      flex-direction: column;
      .report-result {
        flex: 1;
        overflow-y: auto;
        position: relative;
      }
      .van-field {
        padding: 0;
      }
      .danger {
        color: $danger;
      }
      .success {
        color: $success;
      }
    }
  }
}
</style>